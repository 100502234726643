export enum BackofficeApiActions {
  Search = 'search',
  GetDashboardData = 'getDashboardData',
  GetSubAccounts = 'getSubAccounts',
  GetSurveys = 'getSurveys',
  GetAllergyReport = 'getAllergyReport',
  GetManualExclusion = 'getManualExclusion',
  SaveManualExclusion = 'saveManualExclusion',
  DeleteManualExclusion = 'deleteManualExclusion',
  GetManualExclusionModel = 'getManualExclusionModel',
  GetDataset = 'getDataset',
  GetSkinImage = 'getSkinImage',
  GetProductAssignments = 'getProductAssignments',
  GetRecommendation = 'getRecommendation',
  GetOrders = 'getOrders',
  GetDetails = 'getDetails',
  GetImpersonateUrl = 'getImpersonateUrl',
  GetEvaluations = 'getEvaluations',
  GetLineItem = 'getLineItem',
  GetPatientHistory = 'getPatientHistory',
  UpdateEmail = 'updateEmail',
  DeleteCustomer = 'deleteCustomer',
  GetManualAssignmentModel = 'getManualAssignmentModel',
  CreateManualAssignment = 'createManualAssignment',
  DeleteManualAssignment = 'deleteManualAssignment',
  GetDraftOrderModel = 'getDraftOrderModel',
  CreateDraftOrder = 'createDraftOrder',
  GetDraftOrders = 'getDraftOrders',
  GetLinkManagerModel = 'getLinkManagerModel',
  GetImages = 'getImages',
  ClearRecommendation = 'clearRecommendation',
  GetAllPatientConversations = 'getAllPatientConversations',
  GetPatientConversation = 'getPatientConversation',
  UpdatePatientConversation = 'updatePatientConversation',
  CreatePatientConversation = 'createPatientConversation',
  GetFileUploadUrls = 'getFileUploadUrls',
  GenerateAuthTokensReq = 'generateAuthTokensReq',
  GetListOfUsersByDiscountCode = 'getListOfUsersByDiscountCode',
  ListWhatsappTemplates = 'listWhatsappTemplates',
  DeleteWhatsappTemplate = 'deleteWhatsappTemplate',
  CreateWhatsappTemplate = 'createWhatsappTemplate',
  GetInternalConversationMessages = 'getInternalConversationMessages',
  CreateOrUpdateInternalConversation = 'createOrUpdateInternalConversation',
  DeleteInternalConversationMessage = 'deleteInternalConversationMessage',
  GetEmployees = 'getEmployees',
  GetDatasetEditIframeUrl = 'getDatasetEditIframeUrl',
  GetPatientListByDatasetHandle = 'getPatientListByDatasetHandle',
  CreateEvaluation = 'createEvaluation',
  ListLLMModels = 'listLLMModels',
  GetLLMModelDetails = 'getLlmModelDetails',
  ListBMModels = 'listBMModels',
  ListBMModel = 'listBMModel',
  CreateBMModel = 'createBMModel',
  DeleteBMModel = 'deleteBMModel',
  DuplicateBMModel = 'duplicateBMModel',
  GetBMFormModel = 'getBMFormModel',
  ListBMModelClusters = 'listBMModelClusters',
  ListBMModelClusterPriors = 'listBMModelClusterPriors',
  GetBMClusterFormModel = 'getBMClusterFormModel',
  CreateBMCluster = 'createBMCluster',
  DeleteBMCluster = 'deleteBMCluster',
  SetStatusBMCluster = 'setStatusBMCluster',
  CreateBMModelClusterPrior = 'createBMModelClusterPrior',
  DeleteBMModelClusterPrior = 'deleteBMModelClusterPrior',
  CountProducts = 'countProducts',
  ListProducts = 'listProducts',
  CreateProduct = 'createProduct',
  ReadProduct = 'readProduct',
  UpdateProduct = 'updateProduct',
  DeleteProduct = 'deleteProduct',
  FormHelpersProducts = 'formHelpersProducts',
  CountFormulations = 'countFormulations',
  ListFormulations = 'listFormulations',
  CreateFormulation = 'createFormulation',
  ReadFormulation = 'readFormulation',
  UpdateFormulation = 'updateFormulation',
  DeleteFormulation = 'deleteFormulation',
  FormHelpersFormulations = 'formHelpersFormulations',
  CountIngredients = 'countIngredients',
  ListIngredients = 'listIngredients',
  CreateIngredient = 'createIngredient',
  ReadIngredient = 'readIngredient',
  UpdateIngredient = 'updateIngredient',
  DeleteIngredient = 'deleteIngredient',
  FormHelpersIngredients = 'formHelpersIngredients',
  GetSkinFamilies = 'getSkinFamilies',
  GetIngredientInsights = 'getIngredientInsights',
  GetProductInsights = 'getProductInsights',

  GetUserInsightsFilters = 'getUserInsightsFilters',
  GetUserInsightsData = 'getUserInsightsData',

  GetFormulationInsightsFilters = 'getFormulationInsightsFilters',
  GetFormulationInsightsData = 'getFormulationInsightsData',

  GetIngredientInsightsFilters = 'getIngredientInsightsFilters',
  GetIngredientInsightsData = 'getIngredientInsightsData',

  SaveInsightsView = 'saveInsightsView',
  RemoveInsightsView = 'removeInsightsView',
  GetInsightsViews = 'getInsightsViews',
  GetInsightsDashboard = 'getInsightsDashboard',
  CountDataSets = 'countDataSets',
  ListDataSets = 'listDataSets',
  ReadDataSet = 'readDataSet',
  CountDataPoints = 'countDataPoints',
  ListDataPoints = 'listDataPoints',
  ReadDataPoint = 'readDataPoint',
  CountEvaluations = 'countEvaluations',
  ListEvaluations = 'listEvaluations',
  ReadEvaluation = 'readEvaluation',
  CountDataDefinitions = 'countDataDefinitions',
  ListDataDefinitions = 'listDataDefinitions',
  CreateDataDefinition = 'createDataDefinition',
  ReadDataDefinition = 'readDataDefinition',
  UpdateDataDefinition = 'updateDataDefinition',
  DeleteDataDefinition = 'deleteDataDefinition',
  CountMiniModels = 'countMiniModels',
  ListMiniModels = 'listMiniModels',
  CreateMiniModel = 'createMiniModel',
  ReadMiniModel = 'readMiniModel',
  UpdateMiniModel = 'updateMiniModel',
  DeleteMiniModel = 'deleteMiniModel',
  FormHelpersMiniModels = 'formHelpersMiniModels',
  CountSuccessRateIndicators = 'countSuccessRateIndicators',
  ListSuccessRateIndicators = 'listSuccessRateIndicators',
  CreateSuccessRateIndicator = 'createSuccessRateIndicator',
  ReadSuccessRateIndicator = 'readSuccessRateIndicator',
  UpdateSuccessRateIndicator = 'updateSuccessRateIndicator',
  DeleteSuccessRateIndicator = 'deleteSuccessRateIndicator',
  FormHelpersSuccessRateIndicators = 'formHelpersSuccessRateIndicators',
  CountDataSources = 'countDataSources',
  ListDataSources = 'listDataSources',
}
