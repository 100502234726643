import * as React from 'react'
import { Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom'
import { CustomerDashboardTabs } from '@app/pages/Customer/CustomerDashboardTabs'
import { NotFound } from '@app/pages/NotFound/NotFound'
import { useDocumentTitle } from '@app/utils/useDocumentTitle'
import { UtilitiesHome } from '@app/pages/Utilities/Home'
import { useKeycloak } from '@react-keycloak/web'
import { YourPatientsHome } from '@app/pages/YourPatients/Home'
import { ProductHome } from '@app/pages/Product/Home'
import { DataHome } from '@app/pages/Data/Home'
import { BayesianModel } from '@app/pages/Model/Bayesian/BayesianModel'
import { DashboardHome } from '@app/pages/Dashboard/Home'
import { CustomerHome } from '@app/pages/Customer/Home'
import { IngredientAnalysisIndex } from '@app/pages/Insights/IngredientAnalysis/Index'
import { BayesianIndex } from '@app/pages/Model/Bayesian/Index'
import { LLMIndex } from '@app/pages/Model/LLM/Index'
import { UserAnalysisIndex } from '@app/pages/Insights/UserAnalysis/Index'
import { ChatAssistantIndex } from '@app/pages/Insights/ChatAssistant/Index'
import { MiniModelHome } from '@app/pages/MiniModel/Home'
import { FormulationAnalysisIndex } from '@app/pages/Insights/FormulationAnalysis/Index'

let routeFocusTimer: number
export interface IAppRoute {
  label?: string // Excluding the label will exclude the route from the nav sidebar in AppLayout
  /* eslint-disable @typescript-eslint/no-explicit-any */
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  /* eslint-enable @typescript-eslint/no-explicit-any */
  exact?: boolean
  path: string
  title: string
  routes?: undefined
  role?: string
}

export interface IAppRouteGroup {
  label: string
  routes: IAppRoute[]
}

export type AppRouteConfig = IAppRoute | IAppRouteGroup

const routes: AppRouteConfig[] = [
  {
    component: DashboardHome,
    exact: true,
    //label: 'Overview',
    path: '/',
    title: 'NØIE Back Office | Dashboard',
  },

  {
    component: DataHome,
    exact: true,
    label: 'Data collection',
    path: '/data',
    title: 'NØIE Back Office | Data collection',
  },
  {
    component: DataHome,
    exact: true,
    path: '/data/:section?/:action?/:id?',
    title: 'NØIE Back Office | Data management',
  },

  {
    component: MiniModelHome,
    exact: true,
    label: 'Data Refinement',
    path: '/data_refinement/main',
    title: 'NØIE Back Office | Data Refinement',
  },
  {
    component: MiniModelHome,
    exact: true,
    path: '/data_refinement/:section?/:action?/:id?',
    title: 'NØIE Back Office | Data Refinement',
  },

  {
    label: 'Insights',
    routes: [
      {
        component: ChatAssistantIndex,
        exact: true,
        label: 'AI Chat',
        path: '/insights/chat-assistant',
        title: 'NØIE Back Office | NØIE chat assistant',
      },
      {
        component: UserAnalysisIndex,
        exact: true,
        label: 'Users',
        path: '/insights/profile-analysis',
        title: 'NØIE Back Office | Users',
      },
      {
        component: FormulationAnalysisIndex,
        exact: true,
        label: 'Formulations',
        path: '/insights/formulation-analysis',
        title: 'NØIE Back Office | Formulations',
      },
      {
        component: IngredientAnalysisIndex,
        exact: true,
        label: 'Ingredients',
        path: '/insights/ingredient-analysis',
        title: 'NØIE Back Office | Ingredients',
      },
    ],
  },

  /*{
    label: 'Model management',
    routes: [
      {
        component: BayesianIndex,
        exact: true,
        label: 'Recommendation models',
        path: '/model/bayesian',
        title: 'NØIE Back Office | Model management',
      },
      {
        component: LLMIndex,
        exact: true,
        label: 'Insights models',
        path: '/model/llms',
        title: 'NØIE Back Office | Model management',
      },
      {
        component: BayesianModel,
        exact: true,
        path: '/model/:section?/:modelName?',
        title: 'NØIE Back Office | Model management',
      },
    ],
  },*/
  {
    component: BayesianIndex,
    exact: true,
    label: 'Intelligence',
    path: '/model/bayesian',
    title: 'NØIE Back Office | Intelligence',
  },
  {
    component: BayesianModel,
    exact: true,
    path: '/model/:section?/:modelName?',
    title: 'NØIE Back Office | Model management',
  },

  {
    component: ProductHome,
    exact: true,
    label: 'Products',
    path: '/product/main',
    title: 'NØIE Back Office | Products',
  },
  {
    component: ProductHome,
    exact: true,
    path: '/product/:section?/:action?/:id?',
    title: 'NØIE Back Office | Products',
  },

  {
    label: 'Operations',
    routes: [
      {
        component: CustomerHome,
        exact: true,
        label: 'User management',
        path: '/customer',
        title: 'NØIE Back Office | User management',
        role: 'backoffice-management',
      },
      {
        component: CustomerDashboardTabs,
        exact: true,
        path: '/customer/:id/:subid?',
        title: 'NØIE Back Office | User Dashboard',
      },
      {
        component: CustomerDashboardTabs,
        exact: true,
        path: '/customer/:id/conversations/:conversationId?',
        title: 'NØIE Back Office | User Dashboard',
      },
      /*{
        component: YourPatientsHome,
        exact: true,
        label: 'Your patients',
        path: '/',
        title: 'NØIE Back Office | Your patients',
        role: 'backoffice-your-patients',
      },*/
      {
        component: UtilitiesHome,
        exact: true,
        label: 'Tools',
        path: '/utilities',
        title: 'NØIE Back Office | Tools',
        role: 'backoffice-management',
      },
    ],
  },
]

// a custom hook for sending focus to the primary content container
// after a view has loaded so that subsequent press of tab key
// sends focus directly to relevant content
// may not be necessary if https://github.com/ReactTraining/react-router/issues/5210 is resolved
const useA11yRouteChange = () => {
  const { pathname } = useLocation()
  React.useEffect(() => {
    routeFocusTimer = window.setTimeout(() => {
      const mainContainer = document.getElementById('primary-app-container')
      if (mainContainer) {
        mainContainer.focus()
      }
    }, 50)
    return () => {
      window.clearTimeout(routeFocusTimer)
    }
  }, [pathname])
}

const RouteWithTitleUpdates = ({ component: Component, title, ...rest }: IAppRoute) => {
  useA11yRouteChange()
  useDocumentTitle(title)

  function routeWithTitle(routeProps: RouteComponentProps) {
    return <Component {...rest} {...routeProps} />
  }

  const identity = useKeycloak()
  const userRoles = identity.keycloak.tokenParsed?.realm_access?.roles ?? []
  const route = routes.find((p) => p['path'] === rest.path && userRoles.includes(p['role']))
  const role = route && route['role'] ? route['role'] : null

  if (role) {
    if (userRoles.includes(role) && route !== undefined) {
      return <Route render={routeWithTitle} component={route['component']} {...rest} />
    } else {
      return <PageNotFound title="" />
    }
  } else {
    return <Route render={routeWithTitle} {...rest} />
  }
}

const PageNotFound = ({ title }: { title: string }) => {
  useDocumentTitle(title)
  return <Route component={NotFound} />
}

const flattenedRoutes: IAppRoute[] = routes.reduce((flattened, route) => [...flattened, ...(route.routes ? route.routes : [route])], [] as IAppRoute[])

const AppRoutes = (): React.ReactElement => (
  <Switch>
    {flattenedRoutes.map(({ path, exact, component, title }, idx) => (
      <RouteWithTitleUpdates path={path} exact={exact} component={component} key={idx} title={title} />
    ))}
    <PageNotFound title="404 Page Not Found" />
  </Switch>
)

export { AppRoutes, routes }
